import './src/styles/global.css'



export const shouldUpdateScroll= ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	const currentPosition = getSavedScrollPosition(location);

	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 0);

	return false;
};